import React, { Component, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import withErrorHandler from './hoc/withErrorHandler/withErrorHandler';
const Landing = React.lazy(() => import('./containers/Landing/Landing'));
const Customer = React.lazy(() => import('./containers/Customer/Customer'));
const Employee = React.lazy(() => import('./containers/Employee/Employee'));
const Admin = React.lazy(() => import('./containers/Admin/Admin'));

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<div className="App">
					<Suspense fallback={<Spinner variant="primary" />}>
						<Switch>
							<Route path="/customer" component={Customer} />
							<Route path="/employee" component={Employee} />
							<Route path="/admin" component={Admin} />
							<Route path="/" component={Landing} />
						</Switch>
					</Suspense>
				</div>
			</BrowserRouter>
		);
	}
}

export default withErrorHandler(App);
