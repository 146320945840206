import React, { Component } from 'react';
import Modal from '../../components/Modal/Modal';
import axios from '../../axios-api';

const withErrorHandler = (WrappedComponent) => {
	return class extends Component {
		state = {
			error: null,
			modal: false,
		};

		constructor(params) {
			super(params);
			this.reqInterceptor = axios.interceptors.request.use(
				(req) => {
					process.env.NODE_ENV === 'development' && console.log(req);
					this.setState({ error: null });
					return req;
				},
				(error) => {
					console.log(error);
					this.setState({ error: error, modal: true });
					return Promise.reject(error);
				}
			);
			this.resInterceptor = axios.interceptors.response.use(
				(res) => res,
				(error) => {
					console.log(error);
					if (error.response.status >= 500) {
						this.setState({ error: error, modal: true });
					}
					return Promise.reject(
						error.response ? error.response.data.message : null
					);
				}
			);
		}

		errorConfirmedHandler = () => {
			this.setState({ error: null, modal: false });
		};

		render() {
			return (
				<React.Fragment>
					{this.state.error ? (
						<Modal
							show={this.state.modal}
							onHide={() => this.errorConfirmedHandler()}
							title={this.state.error.message}
						>
							<p style={{ whiteSpace: 'pre-line' }}>{this.state.error.stack}</p>
						</Modal>
					) : null}
					<WrappedComponent {...this.props} />
				</React.Fragment>
			);
		}

		componentWillUnmount() {
			axios.interceptors.request.eject(this.reqInterceptor);
			axios.interceptors.request.eject(this.resInterceptor);
		}
	};
};

export default withErrorHandler;
