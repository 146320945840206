import * as actionTypes from '../actions/actionTypes';
import updateObject from '../../utility/updateObject';

const initialState = {
	employees: null,
	employeeData: null,
	employeeAdded: false,
	report: null,
	eAuth: null,
	error: null,
	loading: false,
	loggedOut: null,
};

const employeeReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.EMPLOYEE_LOADING_START:
			return updateObject(state, {
				error: null,
				loading: true,
				loggedOut: null,
				employeeAdded: false,
			});
		case actionTypes.SET_EMPLOYEES:
			return updateObject(state, {
				employees: action.employees,
				error: null,
				loading: false,
			});
		case actionTypes.EMPLOYEE_AUTH_SUCCESS:
			return updateObject(state, {
				employeeData: action.employeeData,
				eAuth: action.eAuth,
				error: null,
				loading: false,
				loggedOut: false,
			});
		case actionTypes.EMPLOYEE_AUTH_LOGOUT:
			return updateObject(state, {
				employeeData: null,
				eAuth: null,
				error: null,
				loading: null,
				loggedOut: true,
			});
		case actionTypes.ADD_EMPLOYEE_SUCCESS:
			return updateObject(state, {
				employeeAdded: true,
				error: null,
				loading: false,
			});
		case actionTypes.SET_EMPLOYEE_REPORT:
			return updateObject(state, {
				report: action.report,
				error: null,
				loading: false,
			});
		case actionTypes.GET_EMPLOYEES_FAILED:
		case actionTypes.EMPLOYEE_AUTH_FAILED:
		case actionTypes.EMPLOYEE_LOGOUT_FAILED:
		case actionTypes.ADD_EMPLOYEE_FAILED:
		case actionTypes.GET_EMPLOYEE_REPORT_FAILED:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		default:
			return state;
	}
};

export default employeeReducer;
