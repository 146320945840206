import * as actionTypes from '../actions/actionTypes';
import updateObject from '../../utility/updateObject';

const initialState = {
	customers: null,
	customerData: null,
	otpSent: false,
	otpVerified: false,
	customerAdded: false,
	cAuth: null,
	error: null,
	loading: false,
	loggedOut: null,
};

const customersReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CUSTOMERS_LOADING_START:
			return updateObject(state, {
				error: null,
				loading: true,
				loggedOut: null,
				customerAdded: false,
			});
		case actionTypes.SET_CUSTOMERS:
			return updateObject(state, {
				customers: action.customers,
				error: null,
				loading: false,
			});
		case actionTypes.CUSTOMER_SEND_OTP_SUCCESS:
			return updateObject(state, {
				otpSent: true,
				error: null,
				loading: false,
			});
		case actionTypes.CUSTOMER_VERIFY_OTP_SUCCESS:
			return updateObject(state, {
				otpVerified: true,
				error: action.message,
				loading: false,
			});
		case actionTypes.CUSTOMER_AUTH_SUCCESS:
			return updateObject(state, {
				customerData: action.customerData,
				cAuth: action.cAuth,
				error: null,
				loading: false,
				otpSent: false,
				otpVerified: false,
				loggedOut: false,
			});
		case actionTypes.CUSTOMER_AUTH_LOGOUT:
			return updateObject(state, {
				customerData: null,
				cAuth: null,
				error: null,
				loading: null,
				loggedOut: true,
			});
		case actionTypes.ADD_CUSTOMER_SUCCESS:
			return updateObject(state, {
				customerAdded: true,
				error: null,
				loading: false,
				otpSent: false,
				otpVerified: false,
			});
		case actionTypes.CUSTOMER_SEND_OTP_FAILED:
			return updateObject(state, {
				error: action.error,
				loading: false,
				otpSent: false,
				otpVerified: false,
			});
		case actionTypes.CUSTOMER_VERIFY_OTP_FAILED:
			return updateObject(state, {
				error: action.error,
				loading: false,
				otpVerified: false,
			});
		case actionTypes.GET_CUSTOMERS_FAILED:
		case actionTypes.CUSTOMER_LOGOUT_FAILED:
		case actionTypes.ADD_CUSTOMER_FAILED:
		case actionTypes.CUSTOMER_SIGNUP_FAILED:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		default:
			return state;
	}
};

export default customersReducer;
