import * as actionTypes from '../actions/actionTypes';
import updateObject from '../../utility/updateObject';

const initialState = {
	tickets: null,
	error: null,
	loading: false,
	ticket: null,
	ticketEdited: false,
	ticketAdded: false,
};

const ticketsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TICKETS_LOADING_START:
			return updateObject(state, {
				error: null,
				loading: true,
				ticketAdded: false,
				ticketEdited: false,
			});
		case actionTypes.SET_TICKET:
			return updateObject(state, {
				ticket: action.ticket,
				error: null,
				loading: false,
			});
		case actionTypes.SET_TICKETS:
			return updateObject(state, {
				tickets: action.tickets,
				error: null,
				loading: false,
			});
		case actionTypes.EDIT_TICKET_SUCCESS:
			return updateObject(state, {
				ticketEdited: true,
				error: null,
				loading: false,
			});
		case actionTypes.ADD_TICKET_SUCCESS:
			return updateObject(state, {
				ticketAdded: true,
				error: null,
				loading: false,
			});
		case actionTypes.GET_TICKET_FAILED:
		case actionTypes.GET_TICKETS_FAILED:
		case actionTypes.EDIT_TICKET_FAILED:
		case actionTypes.ADD_TICKET_FAILED:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		default:
			return state;
	}
};

export default ticketsReducer;
