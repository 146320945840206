export const CUSTOMERS_LOADING_START = 'CUSTOMERS_LOADING_START';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const GET_CUSTOMERS_FAILED = 'GET_CUSTOMERS_FAILED';
export const CUSTOMER_SEND_OTP_SUCCESS = 'CUSTOMER_SEND_OTP_SUCCESS';
export const CUSTOMER_SEND_OTP_FAILED = 'CUSTOMER_SEND_OTP_FAILED';
export const CUSTOMER_VERIFY_OTP_SUCCESS = 'CUSTOMER_VERIFY_OTP_SUCCESS';
export const CUSTOMER_VERIFY_OTP_FAILED = 'CUSTOMER_VERIFY_OTP_FAILED';
export const CUSTOMER_AUTH_SUCCESS = 'CUSTOMER_AUTH_SUCCESS';
export const CUSTOMER_SIGNUP_FAILED = 'CUSTOMER_SIGNUP_FAILED';
export const CUSTOMER_AUTH_LOGOUT = 'CUSTOMER_AUTH_LOGOUT';
export const CUSTOMER_LOGOUT_FAILED = 'CUSTOMER_LOGOUT_FAILED';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILED = 'ADD_CUSTOMER_FAILED';

export const EMPLOYEE_LOADING_START = 'EMPLOYEE_LOADING_START';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const GET_EMPLOYEES_FAILED = 'GET_EMPLOYEES_FAILED';
export const EMPLOYEE_AUTH_SUCCESS = 'EMPLOYEE_AUTH_SUCCESS';
export const EMPLOYEE_AUTH_FAILED = 'EMPLOYEE_AUTH_FAILED';
export const EMPLOYEE_AUTH_LOGOUT = 'EMPLOYEE_AUTH_LOGOUT';
export const EMPLOYEE_LOGOUT_FAILED = 'EMPLOYEE_LOGOUT_FAILED';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILED = 'ADD_EMPLOYEE_FAILED';
export const SET_EMPLOYEE_REPORT = 'SET_EMPLOYEE_REPORT';
export const GET_EMPLOYEE_REPORT_FAILED = 'GET_EMPLOYEE_REPORT_FAILED';

export const ADMIN_LOADING_START = 'ADMIN_LOADING_START';
export const ADMIN_AUTH_SUCCESS = 'ADMIN_AUTH_SUCCESS';
export const ADMIN_AUTH_FAILED = 'ADMIN_AUTH_FAILED';
export const ADMIN_AUTH_LOGOUT = 'ADMIN_AUTH_LOGOUT';
export const ADMIN_LOGOUT_FAILED = 'ADMIN_LOGOUT_FAILED';

export const TICKETS_LOADING_START = 'TICKET_LOADING_START';
export const SET_TICKET = 'SET_TICKET';
export const GET_TICKET_FAILED = 'GET_TICKETS_FAILED';
export const SET_TICKETS = 'SET_TICKETS';
export const GET_TICKETS_FAILED = 'GET_TICKETS_FAILED';
export const EDIT_TICKET_SUCCESS = 'EDIT_TICKET_SUCCESS';
export const EDIT_TICKET_FAILED = 'EDIT_TICKET_FAILED';
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS';
export const ADD_TICKET_FAILED = 'ADD_TICKET_FAILED';
