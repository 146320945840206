import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import employeeReducer from './store/reducers/employee';
import adminReducer from './store/reducers/admin';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import ticketsReducer from './store/reducers/tickets';
import customersReducer from './store/reducers/customer';

const rootReducer = combineReducers({
	customer: customersReducer,
	employee: employeeReducer,
	admin: adminReducer,
	tickets: ticketsReducer,
});

const composeEnhancers = composeWithDevTools({ trace: true });
const store = createStore(
	rootReducer,
	process.env.NODE_ENV === 'development'
		? composeEnhancers(applyMiddleware(thunk))
		: applyMiddleware(thunk)
);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
